import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, tap } from 'rxjs';
import { Project } from '@interfaces/project.interface';
import { ProjectsResponse } from '@interfaces/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  constructor(private http: HttpClient) {}

  public getAllProjects(): Observable<Project[]> {
    const lang = localStorage.getItem('lang');

    const body = `query Query {
        projects {
          uuid
          project_uuid
          hide
          lang_uuid
          short_name
          full_name
          description
          siteUrl
          service_uuid
          industry_uuid
          direction
          crew
          challenges
          solutions
          results
          major_image_url
          sub_image_urls
          stack_items
          technologies
          language {
            uuid
            name
          }
          industry {
            uuid
            industry_uuid
            lang_uuid
            name
            major_industry_name
          }
          service {
            uuid
            service_uuid
            lang_uuid
            name
          }
        }
      }`;

    return this.http
      .post<ProjectsResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [lang] },
        operationName: 'Query',
      })
      .pipe(
        map(res =>
          res.data.projects.map(project => ({
            ...project,
            major_image_url: project.major_image_url ? environment.staticUrl + project.major_image_url : project.major_image_url,
          }))
        ),
      );
  }
}
